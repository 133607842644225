import { PageProps } from 'gatsby';
import { client } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { CollectionSchema } from 'typesense/lib/Typesense/Collection';

import Button from '~/components/Button';
import Title from '~/components/Header/Title';
import CMSView from '~/components/View';
import TemplateAdmin from '~/templates/admin';
import requireUser from '~/utils/requireUser';

const PageAdminCollectionUpdate: FC<PageProps> = ({ params: { name } }) => {
  const [collection, setCollection] = useState<CollectionSchema>();

  useEffect(() => {
    let isMounted = true;
    client
      .collections(name)
      .retrieve()
      .then(newCollection => {
        if (isMounted) {
          setCollection(newCollection);
        }

        return null;
      })
      .catch(console.error);

    return () => {
      isMounted = false;
    };
  }, [name]);

  const handleDeleteOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (window.confirm('Confirmes-tu la suppression de cette collection ?')) {
      client.collections(name).delete();
    }
  };

  const handleSortOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    const index = event.currentTarget.getAttribute('data-index');

    if (null !== index) {
      const field =
        collection?.fields && collection.fields[parseInt(index, 10)];
      if (field) {
        // On change le sort
        const updateSchema = {
          fields: [
            { drop: true, ...field },
            { ...field, sort: !field.sort },
          ],
        };
        await client.collections(name).update(updateSchema);
        client
          .collections(name)
          .retrieve()
          .then(newCollection => {
            setCollection(newCollection);

            return null;
          })
          .catch(console.error);
      }
    }
  };

  return (
    <TemplateAdmin>
      <CMSView>
        <div>
          <Title className="mb-6">{collection?.name}</Title>
          <p>{`il y a ${collection?.num_documents} documents`}</p>
          <hr className="my-6" />
          <div className="flex flex-col space-y-6">
            {collection?.fields?.map((field: any, index) => (
              <div key={field.name}>
                <h3>
                  <strong>{field.name}</strong> ({field.type}){' '}
                  {field.locale && <span>locale : {field.locale}</span>}
                </h3>
                <dl className="grid grid-cols-4 bg-light-100 p-3">
                  <dt>Facet</dt>
                  <dd className="col-span-3">{field.facet ? 'oui' : 'non'}</dd>
                  <dt>Index</dt>
                  <dd className="col-span-3">{field.index ? 'oui' : 'non'}</dd>
                  <dt>Infix</dt>
                  <dd className="col-span-3">{field.infix ? 'oui' : 'non'}</dd>
                  <dt>Optional</dt>
                  <dd className="col-span-3">
                    {field.optional ? 'oui' : 'non'}
                  </dd>
                  <dt>Sort</dt>
                  <dd className="col-span-3 flex space-x-6">
                    <span>{field.sort ? 'oui' : 'non'}</span>
                    <Button
                      datas={{ 'data-index': index }}
                      onClick={handleSortOnClick}
                      variant="link"
                    >
                      changer
                    </Button>
                  </dd>
                </dl>
              </div>
            ))}
          </div>
          <hr className="my-6" />

          <Button
            color="danger"
            onClick={handleDeleteOnClick}
            variant="outline"
          >
            Supprimer la collection
          </Button>
        </div>
      </CMSView>
    </TemplateAdmin>
  );
};

export default requireUser(PageAdminCollectionUpdate, 'admin');
